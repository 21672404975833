import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import charts from '../json-data/charts.json';
import dynamicPage from '../json-data/pages.json';

@Injectable({
  providedIn: 'root',
})
export class PageLayoutService {
  private pages: any[] = dynamicPage.pages;
  private charts: any[] = charts.charts;

  constructor() {}

  getPages(): Observable<any[]> {
    return of(this.pages);
  }

  getCharts(): Observable<any[]> {
    return of(this.charts);
  }

  getPageById(pageId: string): Observable<any> {
    return of(this.pages).pipe(
      map((pages) => {
        const page = pages.find((p) => p.id === pageId);
        if (page) {
          const updatedPage = {
            ...page,
            layout: page.layout.map((layoutItem: { chartId: any }) => ({
              ...layoutItem,
              chartData: this.charts.find(
                (chart) => chart.id === layoutItem.chartId
              ),
            })),
          };
          return updatedPage;
        }
        return null;
      })
    );
  }
}
