{
  "charts": [
    {
      "id": "chart1",
      "data": {
        "title": "Number of Patients",
        "type": "number",
        "view": [600, 250],
        "legend": false,
        "showLabels": true,
        "xAxis": true,
        "yAxis": true,
        "animations": true,
        "data": [{ "name": "Patients", "value": 7342 }]
      }
    },
    {
      "id": "chart2",
      "data": {
        "title": "QME Status",
        "type": "pie",
        "view": [600, 250],
        "legend": false,
        "showLabels": true,
        "animations": true,
        "xAxis": true,
        "yAxis": true,
        "data": [
          { "name": "New or Pending", "value": 35 },
          { "name": "Scheduled", "value": 30 },
          { "name": "Eval Complete", "value": 35 }
        ]
      }
    },
    {
      "id": "chart3",
      "data": {
        "title": "Trend of Intake vs ROR and HX Orders",
        "type": "line",
        "view": [1000, 250],
        "xAxis": true,
        "yAxis": true,
        "legend": true,
        "showLabels": true,
        "animations": true,
        "data": [
          {
            "name": "Intake",
            "series": [
              { "name": "04-01-2024", "value": 54 },
              { "name": "04-08-2024", "value": 48 },
              { "name": "04-15-2024", "value": 59 },
              { "name": "04-22-2024", "value": 61 },
              { "name": "04-29-2024", "value": 53 },
              { "name": "05-06-2024", "value": 60 },
              { "name": "05-13-2024", "value": 57 },
              { "name": "05-20-2024", "value": 55 },
              { "name": "05-27-2024", "value": 56 },
              { "name": "06-03-2024", "value": 52 },
              { "name": "06-10-2024", "value": 58 },
              { "name": "06-17-2024", "value": 54 }
            ]
          },
          {
            "name": "Hx Order",
            "series": [
              { "name": "04-01-2024", "value": 44 },
              { "name": "04-08-2024", "value": 41 },
              { "name": "04-15-2024", "value": 40 },
              { "name": "04-22-2024", "value": 39 },
              { "name": "04-29-2024", "value": 45 },
              { "name": "05-06-2024", "value": 42 },
              { "name": "05-13-2024", "value": 43 },
              { "name": "05-20-2024", "value": 47 },
              { "name": "05-27-2024", "value": 46 },
              { "name": "06-03-2024", "value": 38 },
              { "name": "06-10-2024", "value": 48 },
              { "name": "06-17-2024", "value": 41 }
            ]
          },
          {
            "name": "ROR order",
            "series": [
              { "name": "04-01-2024", "value": 36 },
              { "name": "04-08-2024", "value": 33 },
              { "name": "04-15-2024", "value": 34 },
              { "name": "04-22-2024", "value": 32 },
              { "name": "04-29-2024", "value": 37 },
              { "name": "05-06-2024", "value": 31 },
              { "name": "05-13-2024", "value": 35 },
              { "name": "05-20-2024", "value": 30 },
              { "name": "05-27-2024", "value": 38 },
              { "name": "06-03-2024", "value": 39 },
              { "name": "06-10-2024", "value": 33 },
              { "name": "06-17-2024", "value": 36 }
            ]
          }
        ]
      }
    },
    {
      "id": "chart4",
      "data": {
        "type": "number",
        "title": "Number of Facilities",
        "view": [600, 250],
        "legend": false,
        "showLabels": true,
        "animations": true,
        "xAxis": true,
        "yAxis": true,
        "data": [{ "name": "facilityCount", "value": 20 }]
      }
    },
    {
      "id": "chart5",
      "data": {
        "type": "bar-horizontal",
        "view": [600, 250],
        "title": "Number of Facilities Assigned to Each Provider",
        "legend": false,
        "showLabels": true,
        "animations": true,
        "xAxis": true,
        "yAxis": true,
        "data": [
          { "name": "William Davis", "value": 15 },
          { "name": "David Brown", "value": 3 },
          { "name": "Susan Smith", "value": 6 },
          { "name": "James Johnson", "value": 8 },
          { "name": "Patricia Taylor", "value": 7 }
        ]
      }
    },
    {
      "id": "chart6",
      "data": {
        "title": "Volume of Appointments Per Week",
        "type": "line",
        "view": [600, 250],
        "legend": false,
        "showLabels": true,
        "animations": true,
        "xAxis": true,
        "yAxis": true,
        "data": [
          {
            "name": "Number of Appointments",
            "series": [
              { "name": "Pasadena", "value": 60 },
              { "name": "Greenfield", "value": 53 },
              { "name": "Springfield", "value": 32 },
              { "name": "Riverside", "value": 40 },
              { "name": "Sunnyvale", "value": 64 },
              { "name": "Newport Beach", "value": 36 },
              { "name": "Fairfield", "value": 41 },
              { "name": "Roseville", "value": 20 }
            ]
          }
        ]
      }
    },
    {
      "title": "Volume of Appointments Per Week",
      "type": "line",
      "view": [600, 250],
      "legend": false,
      "showLabels": true,
      "animations": true,
      "xAxis": true,
      "yAxis": true,
      "data": [
        {
          "name": "Number of Appointments",
          "series": [
            { "name": "Pasadena", "value": 60 },
            { "name": "Greenfield", "value": 53 },
            { "name": "Springfield", "value": 32 },
            { "name": "Riverside", "value": 40 },
            { "name": "Sunnyvale", "value": 64 },
            { "name": "Newport Beach", "value": 36 },
            { "name": "Fairfield", "value": 41 },
            { "name": "Roseville", "value": 20 }
          ]
        }
      ]
    },
    {
      "id": "chart7",
      "data": {
        "title": "Volume of Hours Per Week",
        "type": "line",
        "view": [600, 250],
        "legend": false,
        "showLabels": true,
        "animations": true,
        "xAxis": true,
        "yAxis": true,
        "data": [
          {
            "name": "Hours of Appointments",
            "series": [
              { "name": "Pasadena", "value": 140 },
              { "name": "Greenfield", "value": 100 },
              { "name": "Springfield", "value": 145 },
              { "name": "Riverside", "value": 95 },
              { "name": "Sunnyvale", "value": 138 },
              { "name": "Newport Beach", "value": 120 },
              { "name": "Fairfield", "value": 142 },
              { "name": "Roseville", "value": 80 }
            ]
          }
        ]
      }
    },
    {
      "id": "chart8",
      "data": {
        "title": "Number of Patients per Doctor",
        "type": "pie",
        "view": [600, 250],
        "legend": false,
        "showLabels": true,
        "animations": true,
        "xAxis": true,
        "yAxis": true,
        "data": [
          { "name": "Susan Smith", "value": 60 },
          { "name": "James Johnson", "value": 45 },
          { "name": "Patricia Taylor", "value": 50 },
          { "name": "John Williams", "value": 55 },
          { "name": "Linda Brown", "value": 40 },
          { "name": "Michael Jones", "value": 65 },
          { "name": "Barbara Miller", "value": 48 },
          { "name": "William Davis", "value": 58 },
          { "name": "Elizabeth Wilson", "value": 52 },
          { "name": "Richard Anderson", "value": 54 },
          { "name": "Maria Garcia", "value": 47 },
          { "name": "Charles Martinez", "value": 49 },
          { "name": "Thomas Rodriguez", "value": 56 },
          { "name": "Jessica Lee", "value": 51 },
          { "name": "Christopher Hernandez", "value": 42 }
        ]
      }
    },
    {
      "id": "chart9",
      "data": {
        "title": "Monthly Appointment Status",
        "type": "pie",
        "view": [600, 250],
        "legend": false,
        "showLabels": true,
        "animations": true,
        "xAxis": true,
        "yAxis": true,

        "data": [
          { "name": "Scheduled", "value": 320 },
          { "name": "No Show", "value": 50 },
          { "name": "Completed", "value": 60 },
          { "name": "Cancelled", "value": 36 }
        ]
      }
    },
    {
      "id": "chart10",
      "data": {
        "title": "Weekly Appointment Status",
        "type": "bar-vertical",
        "view": [600, 250],
        "xAxis": true,
        "yAxis": true,
        "legend": false,
        "showLabels": true,
        "animations": true,
        "data": [
          { "name": "No Show", "value": 30 },
          { "name": "Scheduled", "value": 120 },
          { "name": "Completed", "value": 70 },
          { "name": "Late Cancelled", "value": 10 },
          { "name": "Cancelled", "value": 6 }
        ]
      }
    },
    {
      "id": "chart11",
      "data": {
        "title": "Weekly Statistics",
        "type": "line",
        "view": [1000, 250],
        "legend": true,
        "showLabels": true,
        "animations": true,
        "xAxis": true,
        "yAxis": true,
        "data": [
          {
            "name": "Intake",
            "series": [
              { "name": "04-01-2024", "value": 22 },
              { "name": "04-08-2024", "value": 19 },
              { "name": "04-15-2024", "value": 21 },
              { "name": "04-22-2024", "value": 18 },
              { "name": "04-29-2024", "value": 20 },
              { "name": "05-06-2024", "value": 19 },
              { "name": "05-13-2024", "value": 22 },
              { "name": "05-20-2024", "value": 23 },
              { "name": "05-27-2024", "value": 18 },
              { "name": "06-03-2024", "value": 21 },
              { "name": "06-10-2024", "value": 20 },
              { "name": "06-17-2024", "value": 23 }
            ]
          },
          {
            "name": "Scheduled",
            "series": [
              { "name": "04-01-2024", "value": 14 },
              { "name": "04-08-2024", "value": 13 },
              { "name": "04-15-2024", "value": 12 },
              { "name": "04-22-2024", "value": 15 },
              { "name": "04-29-2024", "value": 14 },
              { "name": "05-06-2024", "value": 13 },
              { "name": "05-13-2024", "value": 16 },
              { "name": "05-20-2024", "value": 15 },
              { "name": "05-27-2024", "value": 13 },
              { "name": "06-03-2024", "value": 16 },
              { "name": "06-10-2024", "value": 14 },
              { "name": "06-17-2024", "value": 15 }
            ]
          },
          {
            "name": "Billed",
            "series": [
              { "name": "04-01-2024", "value": 15 },
              { "name": "04-08-2024", "value": 14 },
              { "name": "04-15-2024", "value": 15 },
              { "name": "04-22-2024", "value": 14 },
              { "name": "04-29-2024", "value": 13 },
              { "name": "05-06-2024", "value": 14 },
              { "name": "05-13-2024", "value": 16 },
              { "name": "05-20-2024", "value": 15 },
              { "name": "05-27-2024", "value": 16 },
              { "name": "06-03-2024", "value": 15 },
              { "name": "06-10-2024", "value": 14 },
              { "name": "06-17-2024", "value": 17 }
            ]
          }
        ]
      }
    },
    {
      "id": "chart12",
      "data": {
        "title": "Chart Title",
        "type": "number",
        "view": [600, 250],
        "xAxis": true,
        "yAxis": true,
        "legend": false,
        "showLabels": true,
        "animations": true,
        "data": []
      }
    }
  ]
}
