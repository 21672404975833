{
  "pages": [
    {
      "id": "patient",
      "name": "Patient",
      "logo": "person",
      "layout": [
        { "colspan": 2, "rowspan": 1, "chartId": "chart1" },
        { "colspan": 2, "rowspan": 1, "chartId": "chart2" },
        { "colspan": 4, "rowspan": 1, "chartId": "chart3" }
      ]
    },
    {
      "id": "facility",
      "name": "Facility",
      "logo": "business",
      "layout": [
        { "colspan": 2, "rowspan": 1, "chartId": "chart4" },
        { "colspan": 2, "rowspan": 1, "chartId": "chart5" },
        { "colspan": 2, "rowspan": 1, "chartId": "chart6" },
        { "colspan": 2, "rowspan": 1, "chartId": "chart7" }
      ]
    },
    {
      "id": "doctor",
      "name": "Doctor",
      "logo": "local_hospital",
      "layout": [{ "colspan": 4, "rowspan": 1, "chartId": "chart8" }]
    },
    {
      "id": "appointment",
      "name": "Appointment",
      "logo": "event",
      "layout": [
        { "colspan": 2, "rowspan": 1, "chartId": "chart9" },
        { "colspan": 2, "rowspan": 1, "chartId": "chart10" }
      ]
    },
    {
      "id": "billing",
      "name": "Billing",
      "logo": "attach_money",
      "layout": [{ "colspan": 4, "rowspan": 1, "chartId": "chart11" }]
    }
  ]
}
